import React from "react";
import { graphql } from "gatsby";
import Image from "../components/image";
import Topbar from "../components/topbar";

const Contacts = ({ data }) => {
  const { contacts } = data.markdown.frontmatter;
  return (
    <>
      <div>
        <h1>Sustainable Experiences</h1>
        <h3>A day with Jacqueline (Home Hosted Experience)</h3>
        <br />
        <br />
        <br />

        <h2>Heading 2</h2>
        <h4>Heading 4</h4>
        <br />
        <br />
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
          corporis natus quisquam omnis deserunt, modi sequi quo. Unde omnis
          optio voluptate libero laudantium cum eligendi iure. Facilis, esse ut.
        </p>
      </div>
      {/* <div style={{ width: "30rem", height: "8rem", backgroundColor: "red" }}>
        hello
      </div> */}
    </>
  );
};

export const query = graphql`
  query ContactsPageQuery($id: String!, $language: String!) {
    markdown: markdownRemark(id: { eq: $id }) {
      frontmatter {
        contacts {
          icon
          label
          link
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Contacts;
